<template>
  <div>
    <appheader index="3"></appheader>
    <div class="list">
      <div class="list_title">我的收藏</div>
      <div
        :class="['list_box', colleList.length < 6 ? 'list_boxActive' : '']"
        v-loading="loading"
        element-loading-text="正在加载数据"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0,.1)"
      >
        <div class="list_child" @click="getDetails(item)" v-for="(item, index) in colleList" :key="index">
          <svg-icon v-if="item.isPresell === '1'" iconClass="pre-sale-label" className="pre-sale-label"></svg-icon>
          <img :src="item.imgPath" alt="" />
          <div @click.stop="deleteCollection(item.id)">
            <svg-icon iconClass="collection_delete" className="collection_delete"></svg-icon>
          </div>
          <div class="styleClass">{{ item.styleNumber }}</div>
          <div class="titleClass">{{ item.productName }}</div>
          <div class="timerClass">{{ item.updateTime ? item.updateTime : item.createTime }}</div>
          <div class="outStock collectionStock" v-if="item.status == '2'">
            已下架
          </div>
          <div class="outStock collectionStock" v-if="item.status == '1'">
            售完
          </div>
        </div>
        <div v-if="colleList.length <= 0" style="text-align:center;margin:200px 0;width: 100%;">
          <img src="@/Img/noData.png" alt />
        </div>
      </div>
      <div class="boe_fenye">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="obj.current"
          :page-sizes="[12, 24, 36, 48, 60, 120]"
          :page-size="obj.size"
          layout="total, prev, pager, next, sizes, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>
<script>
import Appheader from '../moudel/Appheader.vue';
import bottom from '../bady_bottom/bottom';
import { getList, deleteCollection } from '@/api/collection';
import ls from '@/utils/localStorage';
export default {
  name: 'Collection',
  data() {
    return {
      userData: {},
      obj: {
        current: 1,
        size: 12,
        buyerId: ls.get('userData').userCode,
      },
      loading: true,
      total: 0,
      colleList: [],
    };
  },
  components: {
    Appheader,
    bottom,
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 跳转商品详情页
    getDetails(data) {
      if (data.status == '2') {
        this.$message({
          message: '该商品已下架！',
          type: 'warning',
          duration: 1500,
        });
      } else {
        if (data.classify == '2') {
          this.$router.push({
            path: '/order',
            query: {
              id: data.productId,
              generaName: data.productName,
              styleNumber: data.styleNumber,
              codeType: data.columnCode,
              classify: data.classify,
              groupFlag: data.groupFlag,
            },
          });
        } else if (data.classify == '1') {
          this.$router.push({
            path: '/plaingold',
            query: {
              id: data.productId,
              generaName: data.productName,
              styleNumber: data.styleNumber,
              classify: data.classify,
              groupFlag: data.groupFlag,
            },
          });
        } else if (data.classify == '3') {
          this.$router.push({
            path: '/watch',
            query: {
              id: data.productId,
              generaName: data.title,
              productName: '手表现货',
              serialNumber: data.serialNumber,
              styleNumber: data.styleNumber,
              classify: data.classify,
              groupFlag: data.groupFlag,
            },
          });
        } else if (data.classify == '4') {
          this.$router.push({
            path: '/goldsetting',
            query: {
              id: data.productId,
              generaName: data.productName,
              styleNumber: data.styleNumber,
              classify: data.classify,
              groupFlag: data.groupFlag,
            },
          });
        }
      }
    },
    // 删除收藏
    deleteCollection(id) {
      deleteCollection(id).then((res) => {
        this.$message({
          message: res.data,
          type: 'success',
          duration: 1500,
        });
        this.getList();
      });
    },
    // 分页组件切换页数
    handleSizeChange(val) {
      this.obj.size = val;
      this.getList();
    },
    // 分页组件切换
    handleCurrentChange(val) {
      this.obj.current = val;
      this.getList();
    },
    getList() {
      this.loading = true;
      getList(this.obj).then((res) => {
        this.colleList = [];
        this.total = res.data.total;
        this.loading = false;
        res.data.records.forEach((element) => {
          this.colleList.push(element);
        });
      });
    },
  },
};
</script>
<style scoped lang="scss">
.list {
  max-width: 1500px;
  margin: 0 auto;
  min-height: 880px;
}
.list_title {
  color: #000;
  font-size: 20px;
  font-weight: bold;
  margin: 28px 0;
}
.boe_fenye {
  text-align: center;
  margin-top: 20px;
}
.list_box {
  display: grid;
  grid-row-gap: 20px;
  justify-items: center;
  position: relative;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  .pre-sale-label {
    position: absolute;
    width: 88px;
    height: 28px;
    right: 0;
    top: 0;
  }
}
.list_boxActive {
  display: flex;
  flex-wrap: wrap;
  .list_child {
    margin-left: 8px;
  }
}
.list_child:hover {
  .collection_delete {
    display: block;
    cursor: pointer;
  }
}
.list_child {
  width: 240px;
  border: 1px solid #ec9368;
  box-sizing: border-box;
  position: relative;
  .collection_delete {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 0;
    right: 0;
    display: none;
  }
  img {
    width: 100%;
    height: 238px;
  }
  .styleClass {
    font-size: 16px;
    color: #ec9368;
    margin: 5px 0 5px 8px;
  }
  .titleClass {
    font-size: 16px;
    color: #000;
    margin-left: 8px;
    margin-bottom: 5px;
  }
  .timerClass {
    font-size: 14px;
    color: #999;
    margin-left: 8px;
    margin-bottom: 5px;
  }
  .collectionStock {
    width: 103px;
    height: 103px;
  }
}
</style>
