import request from "@/utils/request";
// 获取收藏页列表数据
export function getList(data) {
  return request({
    url:
      "/exhibition/collection/page?buyerId=" +
      data.buyerId +
      "&size=" +
      data.size +
      "&current=" +
      data.current,
    method: "get",
    params: {},
  });
}
// 删除收藏页数据
export function deleteCollection(id) {
  return request({
    url: `/exhibition/collection/${id}`,
    method: "DELETE",
  });
}
